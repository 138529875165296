import React, { useEffect, useState } from 'react'
import { Typography, Grid, Box, Container, TextField, Divider, Menu, MenuItem, ListItemIcon, Avatar, ListItemText, IconButton, Button, Tooltip, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Close, Pause, Money, Check, MoreVert, FilterList, Pin, CreditCard, AccountBalance, Delete, CalendarMonth, DoNotDisturb, Download, Receipt, AccountBox, CreditScore } from '@mui/icons-material'
import moment from 'moment'

import { Table, SimplePagination, DeleteModal, Tabs } from '../common'
import ClientDetails from './Details'
import ChangePause from './Details/ChangePause'
import ConfirmPayment from './Details/ConfirmPayment'
import LimitedActivation from './Details/LimitedActivation'
import ChangeStartEndDate from './Details/ChangeStartEndDate'
import GenerateInvoice from './Details/GenerateInvoice'
import AddClient from './AddClient'
import PersonalClients from './PersonalClients'
import AdvancedFilters from './AdvancedFilters'
import ChangeTrainer from './Details/ChangeTrainer'

import { numberFomatter } from '../../utils/helpers/numberFomatter'
import * as CLIENTS_REQUESTS from '../../api/clients'
import * as USER_SUBSCRIPTIONS_REQUESTS from '../../api/userSubscriptions'
import * as COMPANY_REQUESTS from '../../api/company'
import { useAuthentication } from '../../context/auth-context'
import { SUBSCRIPTION_PAYMENT_STATUS, ORANGE_COLOR, GREEN_COLOR, PAYMENT_METHOD } from '../../utils/constants'
import { getStatus } from '../../utils/helpers/userSubscription'
import CalendarServiceBookings from './CalendarServiceBookings/CalendarServiceBookings'

const PAGE_SIZE = 30

const Clients = () => {
    const { user } = useAuthentication()
    const { t } = useTranslation()

    const [filters, setFilters] = useState({
        search: '',
        selectedStatus: '',
        selectedPayment: ''
    })
    const [downloading, setDownloading] = useState(false)
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [showAddClient, setShowAddClient] = useState(false)
    const [changeStartEndDate, setChangeStartEndDate] = useState()
    const [selectedClient, setSelectedClient] = useState()
    const [limitedActivation, setLimitedActivation] = useState()
    const [confirmPayment, setConfirmPayment] = useState()
    const [generateInvoice, setGenerateInvoice] = useState()
    const [changePause, setChangePause] = useState()
    const [deleteUserSubscription, setDeleteUserSubscription] = useState()
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [changeTrainer, setChangeTrainer] = useState()
    const [anchorElement, setAnchorElement] = useState(null)
    const [userSubscriptionClientsCount, setUserSubscriptionsClientsCount] = useState()
    const [userSubscriptions, setUserSubscriptions] = useState([])
    const [selectedUserSubscription, setSelectedUserSubscription] = useState()
    const [advancedFilters, setAdvancedFilters] = useState()
    const [currency, setCurrency] = useState('RON')


    const renderPaymentMethod = (method, isBt, payment) => {
        if (method === PAYMENT_METHOD.CARD && !isBt) {
            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Tooltip title={t('subscriptionModal.pos')}>
                    <Avatar variant="square" src={require('../../assets/pos.png')} style={{ width: 25, height: 25, paddingRight: 8 }} />
                </Tooltip>
            </Box>
        }

        if (method === PAYMENT_METHOD.CARD && isBt) {
            if (payment.bankTransferDone) {
                return <Link to={'/payouts'} style={{ textDecoration: 'none', cursor: 'pointer' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title={t('subscriptionModal.bankTransferCard')}>
                            <CreditScore sx={{ paddingRight: 1 }} color='primary' />
                        </Tooltip>
                    </Box>
                </Link>
            }

            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Tooltip title={t('subscriptionModal.card')}>
                    <CreditCard sx={{ paddingRight: 1 }} color='success' />
                </Tooltip>
            </Box>
        }


        if (method === PAYMENT_METHOD.BANK_TRANSFER) {
            return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Tooltip title={t('subscriptionModal.BANK_TRANSFER')}>
                    <AccountBalance sx={{ paddingRight: 1 }} color="success" />
                </Tooltip>
            </Box>
        }

        return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Tooltip title={t('subscriptionModal.cash')}>
                <Money sx={{ paddingRight: 1 }} color="success" />
            </Tooltip>
        </Box>

    }

    const computePaidStatus = (status, paymentMethod, isBt, payment) => {
        if (status.paid) {
            return (
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    {renderPaymentMethod(paymentMethod, isBt, payment)}
                </Box>
            )
        }

        return (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Close color="error" />
                <Typography display="flex" fontWeight={500} color="error" pl={1}>
                    {t('clients.NO')}
                </Typography>
            </Box>
        )
    }

    const computeSubscriptionStatus = (status, isDeleted) => {
        if (isDeleted) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <DoNotDisturb color="error" />
                <Typography fontWeight='bold' color="error">
                    {t('clients.CANCELED')}
                </Typography>
            </Box>
        }

        if (status.paused) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Pause style={{ color: ORANGE_COLOR }} />
                <Typography fontWeight='bold' color={ORANGE_COLOR}>
                    {t('clients.PAUSED')}
                </Typography>
            </Box>
        }

        if (status.expired) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Close color="error" />
                <Typography fontWeight='bold' color="error">
                    {t('clients.EXPIRED')}
                </Typography>
            </Box>
        }

        if (status.inactive) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Close color="error" />
                <Typography fontWeight='bold' color="error">
                    {t('clients.INACTIVE')}
                </Typography>
            </Box>
        }

        return (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Check color="success" />
                <Typography fontWeight='bold' color={GREEN_COLOR}>
                    {t('clients.ACTIVE')}
                </Typography>
            </Box>
        )
    }

    const computeSubscriptionStatusNumber = (status, isDeleted) => {
        if (isDeleted) {
            return 4
        }

        if (status.paused) {
            return 2
        }

        if (status.expired) {
            return 5
        }

        if (status.inactive) {
            return 3
        }

        return 1
    }

    const renderMenuItem = (userSubscription, isPaused, isActive) => {
        return (
            <Box>
                <IconButton onClick={event => {
                    setAnchorElement(event.currentTarget);
                    setSelectedUserSubscription(userSubscription)
                }}>
                    <MoreVert />
                </IconButton>
                <Menu
                    anchorEl={anchorElement}
                    open={selectedUserSubscription && selectedUserSubscription.id === userSubscription.id}
                    onClose={() => {
                        setAnchorElement(null)
                        setSelectedUserSubscription()
                    }}
                >
                    <MenuItem disabled>
                        {t('clients.tableHead.actions')}
                    </MenuItem>
                    <Divider />
                    <MenuItem style={{ minWidth: 200 }} onClick={() => {
                        setAnchorElement(null)
                        setChangePause(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Pause />
                        </ListItemIcon>
                        <ListItemText>
                            {isPaused ? 'Unpause' : 'Pause'}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem style={{ minWidth: 200 }} onClick={() => {
                        setAnchorElement(null)
                        setChangeTrainer(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <AccountBox />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.changeTrainer')}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem style={{ minWidth: 200 }} onClick={() => {
                        setAnchorElement(null)
                        setChangeStartEndDate(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <CalendarMonth />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.editDates')}
                        </ListItemText>
                    </MenuItem>
                    {userSubscription.status !== SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED ? <MenuItem onClick={() => {
                        setAnchorElement(null)
                        setConfirmPayment(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Money />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.confirmPay')}
                        </ListItemText>
                    </MenuItem> : null}
                    {userSubscription.status == SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED && userSubscription.type == PAYMENT_METHOD.CASH ? <Tooltip title={userSubscription.isInvoiceGenerated ? t('clients.regenerateInvoice') : t('clients.generateInvoice')}>
                        <MenuItem onClick={() => {
                            setAnchorElement(null)
                            setGenerateInvoice(userSubscription)
                            setSelectedUserSubscription()
                        }}>
                            <ListItemIcon>
                                <Receipt />
                            </ListItemIcon>
                            <ListItemText>
                                {t('clients.generateInvoice')}
                            </ListItemText>
                        </MenuItem>
                    </Tooltip> : null}
                    {userSubscription.limitedActivationTotal ? <MenuItem onClick={() => {
                        setAnchorElement(null)
                        setLimitedActivation(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Pin />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.credits')}
                        </ListItemText>
                    </MenuItem> : null}
                    {<MenuItem disabled={downloading} onClick={() => downloadPaymentNote(userSubscription.id)}>
                        <ListItemIcon>
                            <Download />
                        </ListItemIcon>
                        <ListItemText>
                            {userSubscription.type === 'CARD' || userSubscription.type === 'BANK_TRANSFER' ? t('clients.invoice') : t('clients.paymentNote')}
                        </ListItemText>
                    </MenuItem>}
                    {!isActive ? <MenuItem onClick={() => {
                        setAnchorElement(null)
                        setDeleteUserSubscription({ ...userSubscription, statuses: { isPaused, isActive } })
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.delete')}
                        </ListItemText>
                    </MenuItem> : null}
                </Menu>
            </Box>
        )
    }

    const downloadPaymentNote = async (userSubscriptionId) => {
        try {
            setDownloading(true)
            await USER_SUBSCRIPTIONS_REQUESTS.downloadPaymentNote(userSubscriptionId)
            setDownloading(false)
        } catch (e) {
            setDownloading(false)
        }
    }

    const removeUserSubscription = userSubscriptionId => USER_SUBSCRIPTIONS_REQUESTS.remove(userSubscriptionId)
    const getClients = ({ companyId, search, from, limit, status, payment, ...restOfOptions }) => {
        setLoading(true)
        return CLIENTS_REQUESTS.getClientsBusiness(companyId, { search, from, limit, status, payment, ...restOfOptions })
            .then(({ count, rows }) => {
                setUserSubscriptionsClientsCount(count)
                setUserSubscriptions(rows)
                setLoading(false)
            })
            .catch(() => setLoading(false))
    }


    const getCompanyCurrency = async () => {
        try {
            const currency = await COMPANY_REQUESTS.getCompanyCurrency(user.companyId);

            setCurrency(currency.currency);
        } catch (error) {
            console.error(`Error getting company currency: ${error}`);
        }
    }

    useEffect(() => {
        getCompanyCurrency()
    }, [user])

    useEffect(() => {
        if (!user) return
        let advancedMappedFilters = {}

        if (advancedFilters) {
            Object.keys(advancedFilters).forEach(key => {
                let value = advancedFilters[key]

                if (key === 'selectedUsers' && value.length) {
                    advancedMappedFilters.userId = value.map(el => el.id)
                } else if (key === 'selectedPaymentMethods' && value.length) {
                    advancedMappedFilters.type = value
                } else if (key === 'selectedSubscriptions' && value.length) {
                    advancedMappedFilters.subscriptionId = value.map(el => el.id)
                } else if (key === 'selectedVouchers' && value.length) {
                    advancedMappedFilters.voucherId = value.map(el => el.id)
                } else if (key === 'minPrice' && value !== null) {
                    advancedMappedFilters.minPrice = value
                } else if (key === 'maxPrice' && value !== null) {
                    advancedMappedFilters.maxPrice = value
                } else if (key === 'minCreditsUsed' && value !== null) {
                    advancedMappedFilters.minLimitedActivationUsed = value
                } else if (key === 'maxCreditsUsed' && value !== null) {
                    advancedMappedFilters.maxLimitedActivationUsed = value
                } else if (key === 'startCreatedAt' && value !== null) {
                    advancedMappedFilters.startCreatedAt = moment(value).add(3, 'hour').toISOString().split('T')[0]
                } else if (key === 'endCreatedAt' && value !== null) {
                    advancedMappedFilters.endCreatedAt = moment(value).add(3, 'hour').toISOString().split('T')[0]
                } else if (key === 'payment' && value !== null) {
                    advancedMappedFilters.payment = value
                } else if (key === 'status' && value !== null) {
                    advancedMappedFilters.status = value
                } else if (key === 'startBookInFuture' && value !== null) {
                    advancedMappedFilters.startBookInFuture = moment(value).add(3, 'hour').toISOString().split('T')[0]
                } else if (key === 'endBookInFuture' && value !== null) {
                    advancedMappedFilters.endBookInFuture = moment(value).add(3, 'hour').toISOString().split('T')[0]
                } else if (key === 'showClientsWithNoBook' && value !== null) {
                    advancedMappedFilters.showClientsWithNoBook = value
                }
            })
        }

        getClients({
            companyId: user.companyId,
            search: filters.search,
            from: (page - 1) * PAGE_SIZE,
            limit: PAGE_SIZE,
            status: filters.selectedStatus,
            ...(advancedFilters ? { ...advancedMappedFilters } : {})
        })
    }, [user, filters, page, advancedFilters])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        const userId = params.get('userId')

        if (userId) {
            setSelectedClient(userId)
        }
    }, [window.location.search])

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50vh"
            >
                <CircularProgress />
            </Box>
        )
    }
    return (
        <>
            {generateInvoice ? <GenerateInvoice
                onCancel={() => setGenerateInvoice()}
                data={generateInvoice}
                onConfirm={() => {
                    let subscription = userSubscriptions.find(el => el.id === generateInvoice.id)
                    subscription.isInvoiceGenerated = true

                    setUserSubscriptions([...userSubscriptions])
                    setGenerateInvoice()
                }}
            /> : null}
            {showAdvancedFilters ? <AdvancedFilters
                onCancel={() => setShowAdvancedFilters(false)}
                data={advancedFilters}
                onConfirm={(allAdvancedFilters) => {
                    setAdvancedFilters(allAdvancedFilters)
                    setShowAdvancedFilters(false)
                }}
            /> : null}
            {showAddClient ? <AddClient
                onCancel={() => setShowAddClient()}
                onConfirm={() => getClients({
                    companyId: user.companyId,
                    search: filters.search,
                    from: (page - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE,
                    status: filters.selectedStatus,
                    payment: filters.selectedPayment
                })}
                currency={currency}
            /> : null}
            {changeStartEndDate ? <ChangeStartEndDate
                onCancel={() => setChangeStartEndDate()}
                onConfirm={() => getClients({
                    companyId: user.companyId,
                    search: filters.search,
                    from: (page - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE,
                    status: filters.selectedStatus,
                    payment: filters.selectedPayment
                })}
                open={!!changeStartEndDate}
                userSubscription={changeStartEndDate}
            /> : null}
            {deleteUserSubscription ?
                <DeleteModal
                    onClose={() => setDeleteUserSubscription()}
                    onDelete={() => removeUserSubscription(deleteUserSubscription.id).then(() => getClients({
                        companyId: user.companyId,
                        search: filters.search,
                        from: (page - 1) * PAGE_SIZE,
                        limit: PAGE_SIZE,
                        status: filters.selectedStatus,
                        payment: filters.selectedPayment
                    })).then(() => setDeleteUserSubscription())}
                    title={deleteUserSubscription.Subscription.name}
                    noDelete={(deleteUserSubscription.statuses.isActive || deleteUserSubscription.statuses.isPaused)}
                    text={(deleteUserSubscription.statuses.isActive || deleteUserSubscription.statuses.isPaused) ? t('clients.cannotDeleteSub') : `${t('clients.deleteMembership')} ${deleteUserSubscription.Subscription.name}. ${t('clients.thisActionWillBePerm')}`}
                />
                : null}
            {limitedActivation ? <LimitedActivation
                onCancel={() => setLimitedActivation()}
                onConfirm={() => getClients({
                    companyId: user.companyId,
                    search: filters.search,
                    from: (page - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE,
                    status: filters.selectedStatus,
                    payment: filters.selectedPayment
                })}
                open={!!limitedActivation}
                userSubscription={limitedActivation}
            /> : null}
            {confirmPayment ? <ConfirmPayment
                onCancel={() => setConfirmPayment()}
                onConfirm={() => getClients({
                    companyId: user.companyId,
                    search: filters.search,
                    from: (page - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE,
                    status: filters.selectedStatus,
                    payment: filters.selectedPayment
                })}
                open={!!changePause}
                userSubscriptionId={confirmPayment.id}
                currency={currency}
            /> : null}
            {changeTrainer ? <ChangeTrainer
                onCancel={() => setChangeTrainer()}
                onConfirm={() => getClients({
                    companyId: user.companyId,
                    search: filters.search,
                    from: (page - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE,
                    status: filters.selectedStatus,
                    payment: filters.selectedPayment
                })}
                open={!!changeTrainer}
                userSubscription={changeTrainer}
            /> : null}
            {changePause ? <ChangePause
                onCancel={() => setChangePause()}
                onConfirm={() => getClients({
                    companyId: user.companyId,
                    search: filters.search,
                    from: (page - 1) * PAGE_SIZE,
                    limit: PAGE_SIZE,
                    status: filters.selectedStatus,
                    payment: filters.selectedPayment
                })}
                open={!!changePause}
                userSubscription={changePause}
            /> : null}
            {selectedClient ? <ClientDetails
                onClose={() => setSelectedClient()}
                userId={selectedClient}
                companyId={user.companyId}
                currency={currency}
            /> : null}
            <Container maxWidth="xl">
                <Grid container pt={8}>
                    <Tabs
                        variant='fullWidth'
                        value={activeTab}
                        onChange={(_, newValue) => {
                            setActiveTab(newValue)
                        }}
                        options={[
                            {
                                label: t('clients.title'),
                                value: <Grid container pt={3} pb={3}>
                                    <Grid item pb={5} xs={12}>
                                        <Grid container flexDirection="row" display="flex">
                                            <Grid item xs={12} md={12} lg={6} display="flex" flexDirection="column" justifyContent="center">
                                                <Typography variant="h5" fontWeight="bold">
                                                    {t('clients.title')}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={12}
                                                lg={6}
                                                justifyContent="flex-end"
                                                flexDirection="row"
                                                display="flex"
                                            >
                                                <Grid
                                                    container
                                                    display="flex"
                                                    flexDirection="row"
                                                    spacing={3}
                                                    justifyContent="flex-end"
                                                >
                                                    <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="flex-end">
                                                        <Button fullWidth variant="contained" onClick={() => setShowAddClient(true)}>
                                                            + {t('clients.clientSubscription')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="flex-end">
                                                        <Button fullWidth variant="outlined" onClick={() => setShowAdvancedFilters(true)}>
                                                            <FilterList /> {t('clients.advancedFilters')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="center">
                                                        <Typography variant="p" pb={0.5}>{t('clients.search')}</Typography>
                                                        <TextField
                                                            onChange={event => setFilters(prevValue => ({ ...prevValue, search: event.target.value }))}
                                                            fullWidth
                                                            value={filters.search}
                                                            placeholder={t('clients.searchClient')}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {advancedFilters ? <Button variant="outlined" onClick={() => setAdvancedFilters()}>Sterge Filtre active <Delete /></Button> : null}
                                    </Grid>
                                    <Grid item xs={12} pb={5}>
                                        <Table
                                            dense
                                            headCells={[
                                                t('clients.Subscription'),
                                                t('clients.Client'),
                                                t('clients.CreatedAt'),
                                                t('clients.Status'),
                                                t('clients.Paid'),
                                                t('clients.Price'),
                                                t('clients.discountName'),
                                                t('clients.availability'),
                                                t('clients.Credits'),
                                                t('clients.Pause'),
                                                t('clients.trainer'),
                                                t('clients.Actions'),
                                            ]}
                                            rows={userSubscriptions.sort((a, b) => {
                                                return moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1
                                            }).map((userSubscription) => {
                                                const userSubscriptionStatus = getStatus(userSubscription)

                                                return {
                                                    subscriptions: (
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontWeight='bold'
                                                        >
                                                            {userSubscription.Subscription.name}
                                                        </Typography>
                                                    ),
                                                    client: (
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontWeight="bold"
                                                            onClick={() => setSelectedClient(userSubscription.User.id)}
                                                            color="primary"
                                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                        >
                                                            {userSubscription.User?.firstName} {userSubscription.User?.lastName}
                                                        </Typography>
                                                    ),
                                                    createdAt: (
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontWeight={'500'}
                                                        >
                                                            {moment(userSubscription.createdAt).format('DD/MM/YYYY hh:mm')}
                                                        </Typography>
                                                    ),
                                                    status: computeSubscriptionStatus(userSubscriptionStatus, userSubscription.isDeleted),
                                                    paid: computePaidStatus(userSubscriptionStatus, userSubscription.type, userSubscription.Payment && userSubscription.Payment.paymentOrderId ? true : false, userSubscription.Payment),
                                                    value: (
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontWeight={'500'}
                                                        >
                                                            {numberFomatter(userSubscription.price)}
                                                        </Typography>
                                                    ),
                                                    discount: (
                                                        <>
                                                            {
                                                                userSubscription.Voucher ? <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <Typography fontWeight='bold' color="primary">
                                                                        {userSubscription.Voucher.discount}
                                                                        {userSubscription.Voucher.hasFixedDiscount ? ` ${userSubscription?.Payment?.currency || currency}` : ' %'}
                                                                    </Typography>
                                                                </Box> : '-'
                                                            }
                                                        </>
                                                    ),
                                                    validFrom: (
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Typography
                                                                variant="subtitle1"
                                                                fontWeight={'500'}
                                                            >
                                                                {moment(userSubscription.validFrom).format('DD/MM/YYYY')}
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle1"
                                                                fontWeight={'500'}
                                                            >
                                                                {moment(userSubscription.validUntil).utc().format('DD/MM/YYYY')}
                                                            </Typography>
                                                        </Box>
                                                    ),
                                                    limitedActivation: (
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontWeight={'500'}
                                                        >
                                                            {userSubscription.limitedActivationTotal ? `${userSubscription.limitedActivationUsed}/${userSubscription.limitedActivationTotal}` : '-'}
                                                        </Typography>
                                                    ),
                                                    pause: (
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontWeight={'500'}
                                                        >
                                                            {userSubscription.Pause ? moment(userSubscription.Pause.startDate).format('DD/MM/YYYY') + ' - ' + moment(userSubscription.Pause.endDate).format('DD/MM/YYYY') : '-'}
                                                        </Typography>
                                                    ),
                                                    trainer: userSubscription.GeneratedTrainer ? <Tooltip title={`${userSubscription.GeneratedTrainer.firstName} ${userSubscription.GeneratedTrainer.lastName}`}><Box> <Avatar src={userSubscription.GeneratedTrainer.avatarPhotoSrc}>
                                                        {userSubscription.GeneratedTrainer ? `${userSubscription.GeneratedTrainer.firstName[0]} ${userSubscription.GeneratedTrainer.lastName[0]}` : ''}
                                                    </Avatar> </Box></Tooltip> : <Typography
                                                        variant="subtitle1"
                                                        fontWeight={'500'}
                                                    >
                                                        -
                                                    </Typography>,
                                                    actiuni: <>{renderMenuItem(userSubscription, userSubscriptionStatus.paused, userSubscriptionStatus.active)}</>
                                                }
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                        <SimplePagination
                                            count={Math.ceil(Number(userSubscriptionClientsCount / PAGE_SIZE))}
                                            onPageChange={setPage}
                                        />
                                    </Grid>
                                </Grid>
                            },
                            {
                                label: 'Client Service Bookings',
                                value: <CalendarServiceBookings />
                            },
                            {
                                label: t('clients.personalClientsTitle'),
                                value: <PersonalClients />,
                            }
                        ]}
                    />
                </Grid>
            </Container>
        </>
    )
}

export default Clients
