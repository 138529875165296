import moment from 'moment'
import axios from './axios'
import FileSaver from 'file-saver'
import { decode } from 'base64-arraybuffer'

export const findById = id => {
    return axios.get(`/userSubscriptions/${id}`).then((res) => res.data)
}

export const update = (id, payload) => {
    return axios.patch(`/userSubscriptions/${id}`, { ...payload }).then((res) => res.data)
}

export const remove = id => {
    return axios.delete(`/userSubscriptions/${id}`).then((res) => res.data)
}

export const createAdmin = payload => {
    return axios.post(`/userSubscriptions`, { ...payload }).then((res) => res.data)
}

export const downloadPaymentNote = (userSubscriptionId) => {
    return axios.post(`/userSubscriptions/${userSubscriptionId}/payment-note`).then((res) => {
        const outputFilename = `SportIn_${res.data.type}_${moment().format('YYYY-MM-DD HH:mm:ss')}.pdf`;

        FileSaver.saveAs(new Blob([decode(res.data.file)]), outputFilename)
        return res.data
    })
}

export const getUserSubscriptionsByCompany = companyId => {
    return axios.get(`/userSubscriptions/company/${companyId}`).then(res => res.data)
}