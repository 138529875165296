import React, { useEffect, useState } from 'react'
import { Typography, Grid, Box, Container, TextField, Divider, Menu, MenuItem, Button, ListItemIcon, ListItemText, IconButton, Select, Card, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Close, Pause, Money, Check, MoreVert, Pin, CreditCard, Receipt, Delete, CalendarMonth, HourglassBottom, DoNotDisturb } from '@mui/icons-material'
import moment from 'moment'

import { Table, Footer, SimplePagination, DeleteModal, } from '../common'
import ClientDetails from './Details'
import ChangePause from './Details/ChangePause'
import ConfirmPayment from './Details/ConfirmPayment'
import LimitedActivation from './Details/LimitedActivation'
import ChangeStartEndDate from './Details/ChangeStartEndDate'
import AddClient from './AddClient'

import { numberFomatter } from '../../utils/helpers/numberFomatter'
import * as CLIENTS_REQUESTS from '../../api/clients'
import * as USER_SUBSCRIPTIONS_REQUESTS from '../../api/userSubscriptions'
import { useAuthentication } from '../../context/auth-context'
import { SUBSCRIPTION_PAYMENT_STATUS, ORANGE_COLOR, GREEN_COLOR, USER_SUBSCRIPTION_STATUS, USER_SUBSCRIPTION_PAYMENT_STATUS, PAYMENT_METHOD, LIGHT_GRAY_BORDER_COLOR, GRAY_TEXT_COLOR } from '../../utils/constants'
import { getStatus } from '../../utils/helpers/userSubscription'

const PAGE_SIZE = 30

const Clients = () => {
    const { user } = useAuthentication()
    const { t } = useTranslation()

    const [filters, setFilters] = useState({
        search: '',
        selectedStatus: '',
        selectedPayment: ''
    })
    const [financial, setFinancial] = useState({
        active: 0,
        inactive: 0,
        paused: 0
    })
    const [showAddClient, setShowAddClient] = useState(false)
    const [changeStartEndDate, setChangeStartEndDate] = useState()
    const [selectedClient, setSelectedClient] = useState()
    const [limitedActivation, setLimitedActivation] = useState()
    const [confirmPayment, setConfirmPayment] = useState()
    const [changePause, setChangePause] = useState()
    const [deleteUserSubscription, setDeleteUserSubscription] = useState()
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [anchorElement, setAnchorElement] = useState(null)
    const [userSubscriptionClientsCount, setUserSubscriptionsClientsCount] = useState()
    const [userSubscriptions, setUserSubscriptions] = useState([])
    const [selectedUserSubscription, setSelectedUserSubscription] = useState()


    const computePaidStatus = (status, paymentMethod) => {
        if (status.paid) {
            return (
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    {paymentMethod === PAYMENT_METHOD.CASH ? <Money color="success" /> : paymentMethod === PAYMENT_METHOD.CARD ? <CreditCard color="success" /> : <Receipt color="success" />}
                    <Typography display="flex" fontWeight={500} color={GREEN_COLOR} pl={1}>
                        {t('clients.yes')}
                    </Typography>
                </Box>
            )
        }

        return (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Close color="error" />
                <Typography display="flex" fontWeight={500} color="error" pl={1}>
                    {t('clients.NO')}
                </Typography>
            </Box>
        )
    }

    const computeSubscriptionStatus = (status, isDeleted) => {
        if (isDeleted) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <DoNotDisturb color="error" />
                <Typography fontWeight='bold' color="error">
                    {t('clients.CANCELED')}
                </Typography>
            </Box>
        }

        if (status.paused) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Pause style={{ color: ORANGE_COLOR }} />
                <Typography fontWeight='bold' color={ORANGE_COLOR}>
                    {t('clients.PAUSED')}
                </Typography>
            </Box>
        }

        if (status.expired) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Close color="error" />
                <Typography fontWeight='bold' color="error">
                    {t('clients.EXPIRED')}
                </Typography>
            </Box>
        }

        if (status.inactive) {
            return <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Close color="error" />
                <Typography fontWeight='bold' color="error">
                    {t('clients.INACTIVE')}
                </Typography>
            </Box>
        }

        return (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Check color="success" />
                <Typography fontWeight='bold' color={GREEN_COLOR}>
                    {t('clients.ACTIVE')}
                </Typography>
            </Box>

        )
    }

    const renderMenuItem = (userSubscription, isPaused, isActive) => {
        return (
            <Box>
                <IconButton onClick={event => {
                    setAnchorElement(event.currentTarget);
                    setSelectedUserSubscription(userSubscription)
                }}>
                    <MoreVert />
                </IconButton>
                <Menu
                    anchorEl={anchorElement}
                    open={selectedUserSubscription && selectedUserSubscription.id === userSubscription.id}
                    onClose={() => {
                        setAnchorElement(null)
                        setSelectedUserSubscription()
                    }}
                >
                    <MenuItem disabled>
                        {t('clients.actions')}
                    </MenuItem>
                    <Divider />
                    <MenuItem style={{ minWidth: 200 }} onClick={() => {
                        setAnchorElement(null)
                        setChangePause(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Pause />
                        </ListItemIcon>
                        <ListItemText>
                            {isPaused ? 'Unpause' : 'Pause'}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem style={{ minWidth: 200 }} onClick={() => {
                        setAnchorElement(null)
                        setChangeStartEndDate(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <CalendarMonth />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.editDates')}
                        </ListItemText>
                    </MenuItem>
                    {userSubscription.status !== SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED ? <MenuItem onClick={() => {
                        setAnchorElement(null)
                        setConfirmPayment(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Money />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.confirmPay')}
                        </ListItemText>
                    </MenuItem> : null}
                    {userSubscription.limitedActivationTotal ? <MenuItem onClick={() => {
                        setAnchorElement(null)
                        setLimitedActivation(userSubscription)
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Pin />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.credits')}
                        </ListItemText>
                    </MenuItem> : null}
                    {isActive ? <MenuItem onClick={() => {
                        setAnchorElement(null)
                        setDeleteUserSubscription({ ...userSubscription, statuses: { isPaused, isActive } })
                        setSelectedUserSubscription()
                    }}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>
                            {t('clients.delete')}
                        </ListItemText>
                    </MenuItem> : null}
                </Menu>
            </Box>
        )
    }

    const removeUserSubscription = userSubscriptionId => USER_SUBSCRIPTIONS_REQUESTS.remove(userSubscriptionId)

    const getClients = (trainerId, search, from, limit, status, payment) => {
        setLoading(true)
        return CLIENTS_REQUESTS.getClientsTrainer(trainerId, search, from, limit, status, payment)
            .then(({ count, rows }) => {
                setUserSubscriptionsClientsCount(count)
                setUserSubscriptions(rows)
                setLoading(false)
            })
            .catch(() => setLoading(false))
    }


    const getRevenue = trainerId => {
        return CLIENTS_REQUESTS.getRevenueTrainer(trainerId)
            .then(setFinancial)
    }

    useEffect(() => {
        if (!user) return

        getRevenue(user.id)
        getClients(user.id, filters.search, (page - 1) * PAGE_SIZE, PAGE_SIZE, filters.selectedStatus, filters.selectedPayment)
    }, [user, filters, page])

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50vh"
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <>
            {showAddClient ? <AddClient
                onCancel={() => setShowAddClient()}
                onConfirm={() => getClients(user.id, filters.search, (page - 1) * PAGE_SIZE, PAGE_SIZE, filters.selectedStatus, filters.selectedPayment)}
            /> : null}
            {changeStartEndDate ? <ChangeStartEndDate
                onCancel={() => setChangeStartEndDate()}
                onConfirm={() => getClients(user.id, filters.search, (page - 1) * PAGE_SIZE, PAGE_SIZE, filters.selectedStatus, filters.selectedPayment)}
                open={!!changeStartEndDate}
                userSubscription={changeStartEndDate}
            /> : null}
            {deleteUserSubscription ?
                <DeleteModal
                    onClose={() => setDeleteUserSubscription()}
                    onDelete={() => removeUserSubscription(deleteUserSubscription.id).then(() => getClients(user.id, filters.search, (page - 1) * PAGE_SIZE, PAGE_SIZE, filters.selectedStatus, filters.selectedPayment)).then(() => setDeleteUserSubscription())}
                    title={deleteUserSubscription.Subscription.name}
                    noDelete={(deleteUserSubscription.statuses.isActive || deleteUserSubscription.statuses.isPaused)}
                    text={(deleteUserSubscription.statuses.isActive || deleteUserSubscription.statuses.isPaused) ? `You can not delete this subscription because is used by the client.` : `Delete the membership ${deleteUserSubscription.Subscription.name}. This action will permanently remove the user subscription.`}
                />
                : null}
            {limitedActivation ? <LimitedActivation
                onCancel={() => setLimitedActivation()}
                onConfirm={() => getClients(user.id, filters.search, (page - 1) * PAGE_SIZE, PAGE_SIZE, filters.selectedStatus, filters.selectedPayment)}
                open={!!limitedActivation}
                userSubscription={limitedActivation}
            /> : null}
            {confirmPayment ? <ConfirmPayment
                onCancel={() => setConfirmPayment()}
                onConfirm={() => getClients(user.id, filters.search, (page - 1) * PAGE_SIZE, PAGE_SIZE, filters.selectedStatus, filters.selectedPayment)}
                open={!!changePause}
                userSubscriptionId={confirmPayment.id}
            /> : null}
            {changePause ? <ChangePause
                onCancel={() => setChangePause()}
                onConfirm={() => getClients(user.id, filters.search, (page - 1) * PAGE_SIZE, PAGE_SIZE, filters.selectedStatus, filters.selectedPayment)}
                open={!!changePause}
                userSubscription={changePause}
            /> : null}
            {selectedClient ? <ClientDetails
                onClose={() => setSelectedClient()}
                userId={selectedClient}
                trainerId={user.id}
                currency={financial.currency}
            /> : null}
            <Container maxWidth="xl">
                <Grid container pt={8} pb={8}>
                    <Grid item pb={5} xs={12}>
                        <Grid container flexDirection="row" display="flex">
                            <Grid item xs={12} md={12} lg={6}>
                                <Typography variant="h5" fontWeight="bold">
                                    {t('clients.title')}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                justifyContent="flex-end"
                                flexDirection="row"
                                display="flex"
                            >
                                <Grid
                                    container
                                    display="flex"
                                    flexDirection="row"
                                    spacing={3}
                                    justifyContent="flex-end"
                                >
                                    <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="flex-end">
                                        <Button fullWidth variant="contained" onClick={() => setShowAddClient(true)}>
                                            + {t('clients.client')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="center">
                                        <Typography variant="p" pb={0.5}>{t('clients.paymentStatus')}</Typography>
                                        <Select
                                            value={filters.selectedPayment}
                                            onChange={event => setFilters(prevValue => ({ ...prevValue, selectedPayment: event.target.value }))}
                                        >
                                            <MenuItem value="">
                                                <em>{t('clients.none')}</em>
                                            </MenuItem>
                                            <MenuItem value={USER_SUBSCRIPTION_PAYMENT_STATUS.PAID}>PAID</MenuItem>
                                            <MenuItem value={USER_SUBSCRIPTION_PAYMENT_STATUS.UNPAID}>UNPAID</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="center">
                                        <Typography variant="p" pb={0.5}>Statuses</Typography>
                                        <Select
                                            value={filters.selectedStatus}
                                            onChange={event => setFilters(prevValue => ({ ...prevValue, selectedStatus: event.target.value }))}
                                        >
                                            <MenuItem value="">
                                                <em>{t('clients.none')}</em>
                                            </MenuItem>
                                            <MenuItem value={USER_SUBSCRIPTION_STATUS.ACTIVE}>{t('clients.active')}</MenuItem>
                                            <MenuItem value={USER_SUBSCRIPTION_STATUS.INACTIVE}>{t('clients.inactive')}</MenuItem>
                                            <MenuItem value={USER_SUBSCRIPTION_STATUS.PAUSED}>{t('clients.paused')}</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={3} display="flex" flexDirection="column" justifyContent="center">
                                        <Typography variant="p" pb={0.5}>{t('clients.search')}</Typography>
                                        <TextField
                                            onChange={event => setFilters(prevValue => ({ ...prevValue, search: event.target.value }))}
                                            fullWidth
                                            value={filters.search}
                                            placeholder={t('clients.searchClient')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pb={6} pt={3}>
                        <Grid container display="flex" flexDirection="row" spacing={3}>
                            <Grid onClick={() => setFilters(prevValue => ({ ...prevValue, selectedStatus: prevValue.selectedStatus === USER_SUBSCRIPTION_STATUS.ACTIVE ? null : USER_SUBSCRIPTION_STATUS.ACTIVE }))} item xs={12} md={12} lg={4} p={4} component={filters.selectedStatus === USER_SUBSCRIPTION_STATUS.ACTIVE ? Card : Box} borderRadius={2} sx={{ cursor: 'pointer' }} >
                                <Typography variant="h5" color='primary' gutterBottom fontWeight={500}>{t('clients.activeNormal')}</Typography>
                                <Typography variant="p" color={GRAY_TEXT_COLOR}>{t('clients.revenueGenerated')}</Typography>
                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', minHeight: 100 }}>
                                    <Money fontSize="large" color="primary" /> <Typography pl={0.5} variant="h6" color="primary" fontWeight="bold">{numberFomatter(financial.active)} {financial.currency}</Typography>
                                </Box>

                            </Grid>

                            <Grid item xs={12} md={12} lg={4} p={4} onClick={() => setFilters(prevValue => ({ ...prevValue, selectedStatus: prevValue.selectedStatus === USER_SUBSCRIPTION_STATUS.PAUSED ? '' : USER_SUBSCRIPTION_STATUS.PAUSED }))} component={filters.selectedStatus === USER_SUBSCRIPTION_STATUS.PAUSED ? Card : Box} borderRadius={2} sx={{ cursor: 'pointer' }} >
                                <Typography variant="h5" gutterBottom fontWeight={500}>{t('clients.pausedNormal')}</Typography>
                                <Typography variant="p" color={GRAY_TEXT_COLOR}>{t('clients.theCount')}</Typography>
                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', minHeight: 100 }}>
                                    <HourglassBottom fontSize="large" /> <Typography pl={0.5} variant="h6" fontWeight="bold">{financial.paused}</Typography>
                                </Box>

                            </Grid>

                            <Grid item xs={12} md={12} lg={4} p={4} onClick={() => setFilters(prevValue => ({ ...prevValue, selectedStatus: prevValue.selectedStatus === USER_SUBSCRIPTION_STATUS.INACTIVE ? null : USER_SUBSCRIPTION_STATUS.INACTIVE }))} component={filters.selectedStatus === USER_SUBSCRIPTION_STATUS.INACTIVE ? Card : Box} borderRadius={2} sx={{ cursor: 'pointer' }}>
                                <Typography variant="h5" color='error' gutterBottom fontWeight={500}>{t('clients.inactiveNormal')}</Typography>
                                <Typography variant="p" color={GRAY_TEXT_COLOR}>{t('clients.theSum')}</Typography>
                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', minHeight: 100 }}>
                                    <Money fontSize="large" color="error" /> <Typography pl={0.5} variant="h6" color="error" fontWeight="bold">{numberFomatter(financial.inactive)} {financial.currency}</Typography>
                                </Box>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} pb={5}>
                        <Table
                            dense
                            headCells={[
                                t('clients.Subscription'),
                                t('clients.Client'),
                                t('clients.CreatedAt'),
                                t('clients.Status'),
                                t('clients.Paid'),
                                t('clients.Price'),
                                t('clients.ValidFrom'),
                                t('clients.ValidUntil'),
                                t('clients.Credits'),
                                t('clients.Pause'),
                                t('clients.Actions'),
                            ]}
                            rows={userSubscriptions.map((userSubscription) => {
                                const userSubscriptionStatus = getStatus(userSubscription)
                                return {
                                    subscriptions: (
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight='bold'
                                        >
                                            {userSubscription.Subscription.name}
                                        </Typography>
                                    ),
                                    client: (
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                            onClick={() => setSelectedClient(userSubscription.User.id)}
                                            color="primary"
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                        >
                                            {userSubscription.User?.firstName} {userSubscription.User?.lastName}
                                        </Typography>
                                    ),
                                    createdAt: (
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={'500'}
                                        >
                                            {moment(userSubscription.createdAt).format('DD/MM/YYYY hh:mm')}
                                        </Typography>
                                    ),
                                    status: computeSubscriptionStatus(userSubscriptionStatus, userSubscriptionStatus.isDeleted),
                                    paid: computePaidStatus(userSubscriptionStatus, userSubscription.type),
                                    value: (
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={'500'}
                                        >
                                            {numberFomatter(userSubscription.price)}
                                        </Typography>
                                    ),
                                    validFrom: (
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={'500'}
                                        >
                                            {moment(userSubscription.validFrom).format('DD/MM/YYYY')}
                                        </Typography>
                                    ),
                                    validUntil: (
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={'500'}
                                        >
                                            {moment(userSubscription.validUntil).utc().format('DD/MM/YYYY')}
                                        </Typography>
                                    ),
                                    limitedActivation: (
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={'500'}
                                        >
                                            {userSubscription.limitedActivationTotal ? `${userSubscription.limitedActivationUsed}/${userSubscription.limitedActivationTotal}` : '-'}
                                        </Typography>
                                    ),
                                    pause: (
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={'500'}
                                        >
                                            {userSubscription.Pause ? moment(userSubscription.Pause.startDate).format('DD/MM/YYYY') + ' - ' + moment(userSubscription.Pause.endDate).format('DD/MM/YYYY') : '-'}
                                        </Typography>
                                    ),
                                    actiuni: <>{renderMenuItem(userSubscription, userSubscriptionStatus.paused, userSubscriptionStatus.active)}</>
                                }
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <SimplePagination
                            count={Math.ceil(Number(userSubscriptionClientsCount / PAGE_SIZE))}
                            onPageChange={setPage}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Clients