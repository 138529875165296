/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { AddCircle, Close } from '@mui/icons-material'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    IconButton,
    Container,
    Button,
    InputAdornment,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { toast } from 'react-toastify'

import * as TRAINER_REQUESTS from '../../../api/trainer'
import * as COMPANY_REQUESTS from '../../../api/company'
import * as CALENDAR_REQUESTS from '../../../api/calendar'

import { Tabs } from '../../common'
import {
    ROLE_TRAINER,
    ROLE_BUSINESS_ADMINISTRATOR,
    PRIMARY_COLOR,
    SPORTTYPES_TYPES,
    EQUIPMENTS_TYPES,
    BUSINESS_ROLES,
    COMPANIES_TRAINERS_REQUEST_STATUS,
    CALENDAR_SERVICES_TYPES,
} from '../../../utils/constants'

import { useAuthentication } from '../../../context/auth-context'
import CalendarServiceDetails from './CalendarServiceDetails'
import CalendarServiceDate from './CalendarServiceDate'
import moment from 'moment-timezone'
import CalendarServiceNotes from './CalendarServiceNotes'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'

const CalendarCreateService = ({ handleClose, Transition, currency }) => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const isTrainer = user && user.role && user.role.toLowerCase() === ROLE_TRAINER.toLowerCase()
    const isBusinessAdministrator =
        user && user.role && user.role.toLowerCase() === ROLE_BUSINESS_ADMINISTRATOR.toLowerCase()
    const isClubAdministrator =
        user && user.role && user.role.toLowerCase() === BUSINESS_ROLES.BUSINESS_EMPLOYEE.toLowerCase()

    const [notes, setNotes] = useState('')
    const [enterPressed, setEnterPressed] = useState(false)
    const [whenType, setWhenType] = useState('schedule')
    const [disable, setDisable] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [subscriptions, setSubscriptions] = useState([])
    const [trainers, setTrainers] = useState([])
    const [selectedTrainerIds, setSelectedTrainerIds] = useState([])
    const [dateInputFocused, setDateInputFocused] = useState(false)
    const [schedule, setSchedule] = useState([
        { name: 'mon', active: false, values: [] },
        { name: 'tue', active: false, values: [] },
        { name: 'wed', active: false, values: [] },
        { name: 'thu', active: false, values: [] },
        { name: 'fri', active: false, values: [] },
        { name: 'sat', active: false, values: [] },
        { name: 'sun', active: false, values: [] }
    ])

    const DATES_INPUTS = [
        {
            name: 'onlineBooking',
            label: t('calendar.onlineBooking'),
            infoMessage: t('calendar.onlineBooking'),
            value: true,
            type: 'toggle'
        },
        {
            name: 'cancel',
            label: t('calendar.cancel'),
            infoMessage: t('calendar.cancel'),
            value: '',
            type: 'number',
            endAdornmentComponent: (
                <InputAdornment position="start">
                    <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                        {t('calendar.hours')}
                    </Typography>
                </InputAdornment>
            )
        },
        {
            name: 'book',
            label: t('calendar.book'),
            infoMessage: t('calendar.book'),
            value: '',
            type: 'number',
            endAdornmentComponent: (
                <InputAdornment position="start">
                    <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                        {t('calendar.hours')}
                    </Typography>
                </InputAdornment>
            )
        },
        {
            name: 'recurring',
            label: t('calendar.recurring'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'recurringWeeks',
            label: t('calendar.weeks'),
            infoMessage: t('calendar.numberOfWeeks'),
            value: '',
            type: 'number',
            disabled: true,
            maxLength: 5,
            placeholder: t('calendar.numberOfWeeks')
        },
        {
            name: 'withTrainers',
            label: t('calendar.withTrainers'),
            value: false,
            type: 'toggle'
        },
        {
            name: 'showInCalendar',
            label: 'Show in Calendar',
            value: true,
            type: 'toggle'
        },
        {
            name: 'showInServices',
            label: 'Show in Services',
            value: true,
            type: 'toggle'
        },
        {
            name: 'withBufferBefore',
            label: 'Buffer Before Event',
            value: false,
            type: 'toggle'
        },
        {
            name: 'withBufferAfter',
            label: 'Buffer After Event',
            value: false,
            type: 'toggle'
        },
        {
            name: 'bufferBefore',
            label: '',
            infoMessage: '',
            value: '',
            type: 'number',
            placeholder: 'Buffer Before Event'
        },
        {
            name: 'bufferAfter',
            label: '',
            infoMessage: '',
            value: '',
            type: 'number',
            disabled: true,
            placeholder: 'Buffer After Event'
        },
        {
            name: 'videoLink',
            label: 'Online Access Link',
            infoMessage: '',
            value: '',
            type: 'text',
            disabled: true,
            placeholder: 'Online Access Link'
        },
        {
            name: 'day',
            label: 'Day',
            infoMessage: 'Day',
            value: '',
            type: 'date',
        },
        {
            name: 'start',
            label: 'Start Hour',
            infoMessage: 'Start Hours',
            value: '',
            type: 'date',
        },
        {
            name: 'end',
            label: 'End Hour',
            infoMessage: 'End Hours',
            value: '',
            type: 'date',
        },
        {
            name: 'interval',
            label: 'Time Interval',
            infoMessage: 'Time Interval',
            value: '',
            type: 'number',
            endAdornmentComponent: (
                <InputAdornment position="start">
                    <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                        MIN
                    </Typography>
                </InputAdornment>
            )
        },
    ]

    const DETAILS_INPUTS = [
        {
            name: 'name',
            label: t('input.label.eventName'),
            mandatory: true,
            value: '',
            type: 'text',
            placeholder: t('input.placeholder.eventName'),
            maxLength: 30,
            endAdornmentComponent: (
                <InputAdornment position="start">
                    <Typography variant="p" fontWeight="bold" color={PRIMARY_COLOR}>
                        30
                    </Typography>
                </InputAdornment>
            )
        },
        {
            name: 'tags',
            label: t('input.label.tags'),
            infoMessage: t('input.placeholder.tags'),
            value: '',
            values: [],
            type: 'customSelect',
            placeholder: '#hiking, crossfit, advanced',
            endAdornmentComponent: (
                <InputAdornment onClick={() => handleOnAdornmentClick('tags')} position="start">
                    <AddCircle sx={{ cursor: 'pointer' }} color="primary" />
                </InputAdornment>
            )
        },
        {
            name: 'sportType',
            label: t('input.label.sportType'),
            mandatory: true,
            infoMessage: t('input.placeholder.sportType'),
            value: 'NONE',
            type: 'select',
            placeholder: 'Type',
            values: Object.keys(CALENDAR_SERVICES_TYPES).map((key) => ({
                label: t(`sportTypesOptions.${CALENDAR_SERVICES_TYPES[key]}`),
                value: CALENDAR_SERVICES_TYPES[key]
            })).sort((a, b) => {
                return a.label < b.label ? - 1 : a.label > b.label ? 1 : 0
            })
        },
        {
            name: 'equipment',
            label: t('input.label.equipments'),
            infoMessage: 'Equipments',
            value: [],
            type: 'select',
            multiple: true,
            values: [{ label: t('helper.resetAll'), value: 'NONE', withoutShowing: true }].concat(Object.keys(EQUIPMENTS_TYPES).map((key) => ({
                label: t(`equipmentsOptions.${EQUIPMENTS_TYPES[key]}`),
                value: EQUIPMENTS_TYPES[key]
            }))),
            placeholder: t('input.placeholder.equipments')
        },
        {
            name: 'location',
            label: t('input.label.eventLocation'),
            mandatory: true,
            infoMessage: 'Event Location',
            value: user.companyLocation || user.locationName || '',
            type: 'google',
            placeholder: t('input.placeholder.eventLocation')
        },
        {
            name: 'maxParticipants',
            mandatory: true,
            label: t('input.label.maxParticipants'),
            infoMessage: t('input.placeholder.maxParticipants'),
            value: '',
            type: 'number',
            placeholder: t('input.placeholder.maxParticipants'),
        },
        {
            name: 'access',
            label: t('input.label.access'),
            mandatory: true,
            infoMessage: t('input.placeholder.access'),
            value: [],
            multiple: true,
            type: 'select',
            placeholder: t('input.placeholder.access'),
            values: [
                { value: 'SUBSCRIPTION', label: t('calendar.subscription'), disabled: false }
            ]
        },
        {
            name: 'subscriptions',
            label: t('input.label.subscriptions'),
            infoMessage: t('input.placeholder.subscriptions'),
            value: [],
            values: [],
            multiple: true,
            disabled: true,
            type: 'select',
            placeholder: t('input.placeholder.subscriptions')
        },
        {
            name: 'cost',
            label: t('input.label.cost'),
            infoMessage: t('input.placeholder.cost'),
            mandatory: true,
            value: [],
            multiple: true,
            disabled: true,
            type: 'select',
            values: [
                // { value: 'RON', label: t('calendar.ron') },
                // { value: 'CREDITS', label: t('calendar.credits') }
            ],
            placeholder: t('input.placeholder.cost')
        },
        {
            name: 'valueCredits',
            label: `${t('input.label.value')} Credits`,
            infoMessage: t('input.placeholder.value'),
            mandatory: true,
            value: '0',
            disabled: true,
            type: 'number',
            placeholder: t('input.placeholder.value')
        },
        {
            name: 'payment',
            label: t('input.label.payment'),
            infoMessage: t('input.placeholder.payment'),
            mandatory: true,
            value: [],
            multiple: true,
            disabled: true,
            type: 'select',
            values: [
                { value: 'CASH', label: t('calendar.cashAtLocation') },
                { value: 'ONLINE', label: t('calendar.onlinePayment') },
            ],
            placeholder: t('input.placeholder.payment')
        },
        {
            name: 'withWaitingList',
            label: t('input.label.withWaitingList'),
            infoMessage: t('input.placeholder.withWaitingList'),
            value: false,
            type: 'toggle',
            placeholder: t('input.placeholder.withWaitingList')
        },
        {
            name: 'maxWaitingList',
            label: t('input.label.maxWaitingList'),
            infoMessage: t('input.placeholder.maxWaitingList'),
            value: 0,
            type: 'number',
            placeholder: t('input.placeholder.maxWaitingList')
        },
    ]

    const [details, setDetails] = useState(DETAILS_INPUTS)
    const [dates, setDates] = useState(DATES_INPUTS)

    useEffect(() => {
        if (!isBusinessAdministrator && !isClubAdministrator) return

        handleGetTrainers()
    }, [isBusinessAdministrator])

    const handleGetTrainers = useCallback(async () => {
        try {
            const company = await COMPANY_REQUESTS.getBusiness(user.companyId)
            setTrainers(
                company.Users.filter(
                    (el) =>
                        el.Companies &&
                        el.Companies[el.Companies.findIndex(comp => comp.id === company.id)] &&
                        el.Companies[el.Companies.findIndex(comp => comp.id === company.id)].Companies_Trainers.status ===
                        COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED
                )
            )
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        if (isTrainer) {
            handleGetTrainerSubscriptions()
        } else {
            handleGetBusinessSubscriptions()
        }
    }, [isTrainer, isBusinessAdministrator])

    const handleGetBusinessSubscriptions = useCallback(() => {
        COMPANY_REQUESTS.getAllSubscriptions(user.companyId).then((res) => {
            const mappedSubscriptions = res.filter(it => it.isAvailable).map((el) => ({
                value: el.id,
                label: el.name
            }))
            setSubscriptions(mappedSubscriptions)
            setDetails((prevDetailsValue) => {
                const prevDetailsValueCopy = [...prevDetailsValue]
                const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'subscriptions')
                const locationPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'location')

                if (inputPosition >= 0) {
                    prevDetailsValueCopy[inputPosition].values = [{ value: 'all', label: t('calendar.selectAll') }].concat(mappedSubscriptions)
                }

                if (locationPosition >= 0) {
                    prevDetailsValueCopy[locationPosition].value = user ? user.companyLocation : ''
                }

                return prevDetailsValueCopy
            })
        })
    }, [user])

    const handleGetTrainerSubscriptions = useCallback(() => {
        TRAINER_REQUESTS.getTrainerSubscriptions().then((res) => {
            const mappedSubscriptions = res.map((el) => ({
                value: el.id,
                label: el.name
            }))
            setSubscriptions(mappedSubscriptions)
            setDetails((prevDetailsValue) => {
                const prevDetailsValueCopy = [...prevDetailsValue]
                const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'subscriptions')
                const locationPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'location')

                if (inputPosition >= 0) {
                    prevDetailsValueCopy[inputPosition].values = [{ value: 'all', label: t('calendar.selectAll') }].concat(mappedSubscriptions)
                }

                if (locationPosition >= 0) {
                    prevDetailsValueCopy[locationPosition].value = user ? user.locationName : ''
                }

                return prevDetailsValueCopy
            })
        })
    }, [user])
    const isEquipmentsAndNone = useCallback((event) => event.target.name === 'equipment' && event.target.value.includes('NONE'), [])
    const isSubscriptionsAndSelectedAll = useCallback((event) => event.target.name === 'subscriptions' && event.target.value.includes('all'), [])
    const isAccessAndSubscriptionValue = useCallback((event) => event.target.name === 'access' && event.target.value.includes('SUBSCRIPTION'), [])
    const isAccessAndNoValue = useCallback((event) => event.target.name === 'access' && event.target.value.length === 0, [])
    const isCostAndCreditsValue = useCallback((event) => event.target.name === 'cost' && event.target.value.includes('CREDITS'), [])
    const isCostAndRonValue = useCallback((event) => event.target.name === 'cost' && event.target.value.includes(currency), [])
    const isCostAndNotCreditsValue = useCallback((event) => event.target.name === 'cost' && !event.target.value.includes('CREDITS'), [])
    const isCostAndNotRonValue = useCallback((event) => event.target.name === 'cost' && !event.target.value.includes(currency), [])
    const isMinParticipantsAndValueGraterThanMaxParticipants = useCallback((event, detailsToUse) => {
        if (event.target.name !== 'minParticipants') return false
        const maxParticipantsIndex = detailsToUse.findIndex(el => el.name === 'maxParticipants')
        const maxParticipantsValue = detailsToUse[maxParticipantsIndex].value

        return event.target.name === 'minParticipants' && Number(maxParticipantsValue) && Number(event.target.value) && (Number(maxParticipantsValue) <= Number(event.target.value))
    }, [])
    const computeDetailsDisabled = useCallback((inputs) => {
        let inputsCopy = [...inputs]
        inputsCopy = inputsCopy.map((input, index) => {
            if (input.name === 'access') {
                const subscriptionsPosition = inputs.findIndex(el => el.name === 'subscriptions')
                if (subscriptionsPosition < 0) return
                inputsCopy[subscriptionsPosition].disabled = !input.value.includes('SUBSCRIPTION')
            }
            if (input.name === 'cost') {
                const valueCreditsPosition = inputs.findIndex(el => el.name === 'valueCredits')

                inputsCopy[valueCreditsPosition].disabled = !input.value.includes('CREDITS')
            }
            if (input.name === 'payment') {
                const accessPosition = inputsCopy.findIndex(el => el.name === 'access')
                const costPosition = inputsCopy.findIndex(el => el.name === 'cost')

                inputsCopy[index].disabled = (inputsCopy[accessPosition].value.length === 1 && inputsCopy[accessPosition].value[0] === 'PUBLIC') && inputsCopy[costPosition].value.length === 0
            }
            return { ...input }
        })
        return inputsCopy
    }, [])

    const handleOnDetailsInputChanged = useCallback((event) => {
        setDetails((prevDetailsValue) => {
            let prevDetailsValueCopy = [...prevDetailsValue]
            const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === event.target.name)
            let custom = false
            if (inputPosition < 0) return

            if (isEquipmentsAndNone(event)) {
                prevDetailsValueCopy[inputPosition].value = []
                custom = true
            }
            if (isSubscriptionsAndSelectedAll(event)) {
                prevDetailsValueCopy[inputPosition].value = subscriptions.map(sub => sub.value)
                custom = true
            }
            if (isAccessAndNoValue(event)) {
                const costPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'cost')
                const valueCreditPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'valueCredits')
                if (costPosition >= 0) {
                    prevDetailsValueCopy[costPosition].values = []
                    prevDetailsValueCopy[costPosition].value = []
                    prevDetailsValueCopy[costPosition].disabled = true

                    prevDetailsValueCopy[valueCreditPosition].disabled = true
                    prevDetailsValueCopy[valueCreditPosition].value = ''
                }
            }
            if (isAccessAndSubscriptionValue(event)) {
                const costPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'cost')
                if (costPosition >= 0) {
                    prevDetailsValueCopy[costPosition].values = [
                        {
                            value: 'CREDITS', label: t('calendar.credits')
                        }
                    ]
                    prevDetailsValueCopy[costPosition].value = ['CREDITS']
                    prevDetailsValueCopy[costPosition].disabled = false
                }
            }
            if (isAccessAndSubscriptionValue(event)) {
                const paymentPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'payment')
                if (paymentPosition >= 0) {
                    prevDetailsValueCopy[paymentPosition].disabled = true
                    prevDetailsValueCopy[paymentPosition].value = []
                }
            }
            if (isAccessAndSubscriptionValue(event)) {
                const paymentPosition = prevDetailsValueCopy.findIndex((el) => el.name === 'payment')
                if (paymentPosition >= 0) {
                    prevDetailsValueCopy[paymentPosition].disabled = false
                }
            }
            if (isCostAndNotCreditsValue(event)) {
                const valueCredits = prevDetailsValueCopy.findIndex((el) => el.name === 'valueCredits')
                if (valueCredits >= 0) {
                    prevDetailsValueCopy[valueCredits].value = 0
                }
            }
            if (isMinParticipantsAndValueGraterThanMaxParticipants(event, prevDetailsValueCopy)) {
                const maxParticipantsIndex = prevDetailsValueCopy.findIndex(el => el.name === 'maxParticipants')
                const maxParticipantsValue = prevDetailsValueCopy[maxParticipantsIndex].value
                prevDetailsValueCopy[inputPosition].value = Number(maxParticipantsValue) - 1
                custom = true
            }
            if (event.target.name === 'name') {
                prevDetailsValueCopy[inputPosition].value = event.target.value.toUpperCase()
                custom = true
            }
            if (event.target.name === 'value') {
                if (prevDetailsValueCopy[inputPosition].type === 'number' && Number(event.target.value.replace(',', '').replace('.', '')) < 0) {
                    prevDetailsValueCopy[inputPosition].value = 0
                }
                else {
                    prevDetailsValueCopy[inputPosition].value = event.target.value.replace(',', '').replace('.', '')
                }
                custom = true
            }

            if (!custom) {
                if (prevDetailsValueCopy[inputPosition].type === 'number' && Number(event.target.value) < 0) {
                    prevDetailsValueCopy[inputPosition].value = 0
                }
                else {
                    prevDetailsValueCopy[inputPosition].value = event.target.value
                }
            }
            prevDetailsValueCopy = computeDetailsDisabled(prevDetailsValueCopy)

            return prevDetailsValueCopy
        })
    }, [subscriptions])


    const handleOnDatesInputChanged = useCallback(
        (event) => {
            setDates((prevDatesValue) => {
                const prevDatesValueCopy = [...prevDatesValue]
                const inputPosition = prevDatesValueCopy.findIndex((el) => el.name === event.target.name)

                if (inputPosition < 0) return

                if (event.target.name === 'recurringWeeks' && event.target.value > 5) {
                    toast.error("Number of recurring weeks cannot be bigger than 5.", { position: 'bottom-right' })
                    return prevDatesValueCopy
                }

                if (prevDatesValueCopy[inputPosition].type === 'number' && Number(event.target.value) < 0) {
                    prevDatesValueCopy[inputPosition].value = 0
                }
                else {
                    prevDatesValueCopy[inputPosition].value = event.target.value
                }
                if (event.target.name === 'onlineBooking' && !event.target.value) {
                    const accessOnlyOnStartPosition = prevDatesValueCopy.findIndex(el => el.name === 'accessOnlyOnStart')
                    const cancelPosition = prevDatesValueCopy.findIndex(el => el.name === 'cancel')
                    const bookPosition = prevDatesValueCopy.findIndex(el => el.name === 'book')
                    if (accessOnlyOnStartPosition >= 0) {
                        prevDatesValueCopy[accessOnlyOnStartPosition].value = false
                        prevDatesValueCopy[cancelPosition].value = 0
                        prevDatesValueCopy[bookPosition].value = 0
                    }
                }

                return prevDatesValueCopy
            })
        },
        []
    )

    const handleCreateService = useCallback(async () => {
        try {
            const servicePayload = {}

            const slots = []

            const bufferAfter = servicePayload['bufferAfter'] = dates[dates.findIndex(el => el.name === 'bufferAfter')].value

            if (!details[details.findIndex(el => el.name === 'maxParticipants')].value) {
                toast.error("You have to provide value for maximum participants", { position: 'bottom-right' })
                return
            }

            setDisable(true)
            if (whenType === 'specific') {
                const dayIndex = dates.findIndex(el => el.name === 'day')
                const startHourIndex = dates.findIndex(el => el.name === 'start')
                const endHourIndex = dates.findIndex(el => el.name === 'end')
                const intervalIndex = dates.findIndex(el => el.name === 'interval')

                const endMoment = moment(dates[dayIndex].value).set('hour', dates[endHourIndex].value.split(':')[0]).set('minutes', dates[endHourIndex].value.split(':')[1])
                const startMoment = moment(dates[dayIndex].value).set('hour', dates[startHourIndex].value.split(':')[0]).set('minutes', dates[startHourIndex].value.split(':')[1])

                const differenceInMinutes = endMoment.diff(startMoment, 'minutes')
                const numberOfSlots = Math.ceil(differenceInMinutes / dates[intervalIndex].value)

                if (differenceInMinutes < 0) return

                Array.from({ length: numberOfSlots }).forEach((_, index) => {
                    const startMinutes = (Number(dates[intervalIndex].value) + (index > 0 ? Number(bufferAfter) : 0)) * index
                    const endMinutes = startMinutes + Number(dates[intervalIndex].value)

                    slots.push({
                        from: moment(dates[dayIndex].value).set('hour', dates[startHourIndex].value.split(':')[0]).set('minutes', dates[startHourIndex].value.split(':')[1]).add(startMinutes, 'minutes'),
                        end: moment(dates[dayIndex].value).set('hour', dates[startHourIndex].value.split(':')[0]).set('minutes', dates[startHourIndex].value.split(':')[1]).add(endMinutes, 'minutes')
                    })
                })
            }
            else {
                const todayIndex = schedule.findIndex(({ name }) => name.toLowerCase() === moment().format('ddd').toLowerCase())

                schedule.forEach(({ name, active, values }, index) => {
                    if (!active || todayIndex === -1) return;
                    let startDay;
                    if (todayIndex > index) {
                        const untilEndOfWeek = moment().endOf('week').diff(moment(), 'days') + 1
                        if (todayIndex === 6 && index === 0) {
                            startDay = moment().add(1, 'day');
                        } else {
                            startDay = moment().add(untilEndOfWeek + index + 1, 'days');
                        }
                    } else if (todayIndex < index) {
                        startDay = moment().add(index - todayIndex, 'days');
                    } else {
                        startDay = moment();
                    }
                    values.forEach(({ start, end, duration }) => {
                        const startMoment = moment(startDay).set('hour', start.split(':')[0]).set('minutes', start.split(':')[1]);
                        const endMoment = moment(startDay).set('hour', end.split(':')[0]).set('minutes', end.split(':')[1]);
                        const differenceInMinutes = endMoment.diff(startMoment, 'minutes');
                        const numberOfSlots = Math.ceil(differenceInMinutes / Number(duration));
                        if (differenceInMinutes < 0) return;
                        Array.from({ length: numberOfSlots }).forEach((_, index) => {
                            const startMinutes = (Number(duration) + (index > 0 ? Number(bufferAfter) : 0)) * index;
                            const endMinutes = startMinutes + Number(duration);
                            slots.push({
                                from: moment(startMoment).add(startMinutes, 'minutes').tz('Europe/Bucharest').format(),
                                end: moment(startMoment).add(endMinutes, 'minutes').tz('Europe/Bucharest').format()
                            });
                        });
                        const recurringPosition = dates.findIndex(el => el.name === 'recurring');
                        const recurringWeeksPosition = dates.findIndex(el => el.name === 'recurringWeeks');
                        if (recurringPosition >= 0 && recurringWeeksPosition >= 0) {
                            if (dates[recurringPosition].value && Number(dates[recurringWeeksPosition].value) > 0) {
                                Array.from({ length: Number(dates[recurringWeeksPosition].value) }).forEach((_, weekIndex) => {
                                    const recurringStartMoment = moment(startDay).add(weekIndex * 7, 'days');
                                    Array.from({ length: numberOfSlots }).forEach((_, slotIndex) => {
                                        const startMinutes = (Number(duration) + (slotIndex > 0 ? Number(bufferAfter) : 0)) * slotIndex;
                                        const endMinutes = startMinutes + Number(duration);
                                        slots.push({
                                            from: moment(recurringStartMoment).add(startMinutes, 'minutes').tz('Europe/Bucharest').format(),
                                            end: moment(recurringStartMoment).add(endMinutes, 'minutes').tz('Europe/Bucharest').format()
                                        });
                                    });
                                });
                            }
                        }
                    });
                });
            }

            servicePayload['slots'] = slots
            servicePayload['sportType'] = details[details.findIndex(el => el.name === 'sportType')].value
            servicePayload['equipment'] = details[details.findIndex(el => el.name === 'equipment')].value
            servicePayload['access'] = details[details.findIndex(el => el.name === 'access')].value
            servicePayload['cost'] = details[details.findIndex(el => el.name === 'cost')].value
            servicePayload['payment'] = details[details.findIndex(el => el.name === 'payment')].value
            servicePayload['name'] = details[details.findIndex(el => el.name === 'name')].value
            servicePayload['maxParticipants'] = details[details.findIndex(el => el.name === 'maxParticipants')].value || 0
            servicePayload['valueCredits'] = details[details.findIndex(el => el.name === 'valueCredits')].value || 0
            servicePayload['withWaitingList'] = details[details.findIndex(el => el.name === 'withWaitingList')].value
            servicePayload['maxWaitingList'] = details[details.findIndex(el => el.name === 'maxWaitingList')].value || 0
            servicePayload['location'] = details[details.findIndex(el => el.name === 'location')].value
            servicePayload['subscriptions'] = details[details.findIndex(el => el.name === 'subscriptions')].value
            servicePayload['tags'] = details[details.findIndex(el => el.name === 'tags')].values
            servicePayload['notes'] = notes

            servicePayload['onlineBooking'] = dates[dates.findIndex(el => el.name === 'onlineBooking')].value
            servicePayload['withBufferBefore'] = dates[dates.findIndex(el => el.name === 'withBufferBefore')].value
            servicePayload['withBufferAfter'] = dates[dates.findIndex(el => el.name === 'withBufferAfter')].value
            servicePayload['bufferBefore'] = Number(dates[dates.findIndex(el => el.name === 'bufferBefore')].value)
            servicePayload['bufferAfter'] = Number(dates[dates.findIndex(el => el.name === 'bufferAfter')].value)
            servicePayload['cancel'] = dates[dates.findIndex(el => el.name === 'cancel')].value || 0
            servicePayload['book'] = dates[dates.findIndex(el => el.name === 'book')].value || 0
            servicePayload['start'] = whenType === 'specific' ? moment(dates[dates.findIndex(el => el.name === 'day')].value).format('YYYY-MM-DD') : moment(slots[0].from).format('YYYY-MM-DD HH:mm:ss')
            servicePayload['videoLink'] = dates[dates.findIndex(el => el.name === 'videoLink')].value
            servicePayload['ownerId'] = user.companyId ? user.companyId : user.id
            servicePayload['forType'] = user.companyId ? 'company' : 'trainer'
            servicePayload['trainers'] = selectedTrainerIds

             // Set the Latitude and Longitude for the event
             const locationIndex = details[details.findIndex(input => input.name === 'location')];

             await geocodeByAddress(locationIndex.value)
                 .then(result => {
                     getLatLng(result[0])
                     .then(({ lat, lng }) => {
                         servicePayload['locationLat'] = String(lat);
                         servicePayload['locationLng'] = String(lng);
                     })
                 })
 
            await CALENDAR_REQUESTS.createCalendarService(servicePayload)
            setDisable(false)
            handleClose()
        } catch (e) {
            setDisable(false)
            toast.error('Ups something went wrong', { position: 'bottom-right' })
            console.error(e)
        }
    }, [user, whenType, notes, trainers, schedule, selectedTrainerIds, dates, details])

    const handleOnNextButton = useCallback(() => {
        if (activeTab === 2) return handleCreateService()

        setActiveTab((prevActiveTab) => prevActiveTab + 1)
    }, [activeTab, handleCreateService, handleClose])

    const handleOnPrevButton = useCallback(() => {
        if (activeTab === 0) return handleClose()

        setActiveTab((prevActiveTab) => prevActiveTab - 1)
    }, [activeTab, handleClose])

    const handleSelectTrainer = useCallback((trainerId) => {
        setSelectedTrainerIds(prevValue => {
            const newPrevValue = prevValue.concat(trainerId)
            return newPrevValue
        })
    })

    const handleRemoveTrainer = useCallback((trainerId) => {
        setSelectedTrainerIds(prevValue => {
            const newPrevValue = prevValue.filter(el => el !== trainerId)
            return newPrevValue
        })
    }, [])

    const handleEnterPressed = useCallback(() => {
        setEnterPressed(true)
    }, [])

    useEffect(() => {
        if (!enterPressed) return
        handleOnAdornmentClick('tags')
    }, [enterPressed])

    const maximumTagsExceededCondition = useCallback((numberOfTags) => numberOfTags === 3, [])

    const handleOnAdornmentClick = useCallback((inputName, directValue) => {
        setDetails((prevDetailsValue) => {
            const prevDetailsValueCopy = [...prevDetailsValue]
            const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === inputName)
            let exceeded = false

            if (inputPosition < 0) return

            if (maximumTagsExceededCondition(prevDetailsValueCopy[inputPosition].values.length)) {
                toast.error(t('calendar.maxTags'), { position: 'bottom-right', autoClose: false })
                exceeded = true
            }

            if (!exceeded) {
                prevDetailsValueCopy[inputPosition].values = [
                    ...prevDetailsValueCopy[inputPosition].values,
                    (directValue ? directValue.toUpperCase() : prevDetailsValueCopy[inputPosition].value.toUpperCase())
                ]
            }
            prevDetailsValueCopy[inputPosition].value = ''

            return prevDetailsValueCopy
        })
        setEnterPressed(false)
    }, [maximumTagsExceededCondition])

    const handleOnDeleteCustomSelect = useCallback((inputName, toDelete) => {
        setDetails((prevDetailsValue) => {
            const prevDetailsValueCopy = [...prevDetailsValue]
            const inputPosition = prevDetailsValueCopy.findIndex((el) => el.name === inputName)

            if (inputPosition < 0) return
            prevDetailsValueCopy[inputPosition].values = prevDetailsValueCopy[
                inputPosition
            ].values.filter((el) => el !== toDelete)

            return prevDetailsValueCopy
        })
    }, [])

    const handleOnNotificationChanged = useCallback((inputName, value) => {
        setDates((prevDatesValue) => {
            const prevDatesValueCopy = [...prevDatesValue]
            const inputPosition = prevDatesValueCopy.findIndex((el) => el.name === inputName)

            if (inputPosition < 0) return
            prevDatesValueCopy[inputPosition].value = prevDatesValueCopy[inputPosition].value.includes(
                value
            )
                ? prevDatesValueCopy[inputPosition].value.filter((el) => el !== value)
                : prevDatesValueCopy[inputPosition].value.concat(value)

            return prevDatesValueCopy
        })
    })

    const handleAddValueToSchedule = useCallback((scheduleName) => {
        setSchedule((prevScheduleValue) => {
            const prevScheduleValueCopy = [...prevScheduleValue]
            const schedulePosition = prevScheduleValueCopy.findIndex((el) => el.name === scheduleName)

            if (schedulePosition < 0) return
            prevScheduleValueCopy[schedulePosition].values = prevScheduleValueCopy[
                schedulePosition
            ].values.concat([
                {
                    start: '',
                    end: '',
                    duration: ''
                }
            ])
            prevScheduleValueCopy[schedulePosition].active = true
            return prevScheduleValueCopy
        })
    }, [])

    const handleRemoveValueFromSchedule = useCallback((scheduleName, positionToDelete) => {
        setSchedule((prevScheduleValue) => {
            const prevScheduleValueCopy = [...prevScheduleValue]
            const schedulePosition = prevScheduleValueCopy.findIndex((el) => el.name === scheduleName)

            if (schedulePosition < 0) return
            prevScheduleValueCopy[schedulePosition].values.splice(positionToDelete, 1)

            if (!prevScheduleValueCopy[schedulePosition].values.length) {
                prevScheduleValueCopy[schedulePosition].active = false
            }
            return prevScheduleValueCopy
        })
    }, [])

    const handleChangeValueFromSchedule = useCallback(
        (scheduleName, positionToUpdate, field, value, dateInputFocusedToUse) => {
            setSchedule((prevScheduleValue) => {
                const prevScheduleValueCopy = [...prevScheduleValue]
                const schedulePosition = prevScheduleValueCopy.findIndex((el) => el.name === scheduleName)

                if (schedulePosition < 0) return
                if (positionToUpdate !== undefined) {
                    if (dateInputFocusedToUse) {
                    }
                    prevScheduleValueCopy[schedulePosition].values[positionToUpdate][field] = value
                } else {
                    prevScheduleValueCopy[schedulePosition][field] =
                        !prevScheduleValueCopy[schedulePosition][field]
                    if (prevScheduleValueCopy[schedulePosition][field] && !prevScheduleValueCopy[schedulePosition].values.length) {
                        handleAddValueToSchedule(scheduleName)
                    }
                }

                return prevScheduleValueCopy
            })
        },
        []
    )

    const handleOnNotesChanged = useCallback((newNotesValue) => {
        setNotes(newNotesValue)
    }, [])

    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'xl'}
            fullScreen
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    borderRadius: 0,
                    paddingTop: '3%'
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Container maxWidth="xl">
                <>
                    <DialogTitle
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        p={4}
                        pl={{ xs: 2, md: 2, lg: 3 }}
                    >
                        <Grid container display="flex" flexDirection="row">
                            <Grid item lg={10} xs={10}>
                                <Typography variant="h5" fontWeight="bold">
                                    Create New Service
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                                <IconButton style={{ color: 'black' }} onClick={handleClose}>
                                    <Close fontSize="medium" color="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid pt={4} item lg={12}>
                            <Tabs
                                variant="fullWidth"
                                value={activeTab}
                                onChange={(_, newValue) => {
                                    setActiveTab(newValue)
                                }}
                                options={[
                                    {
                                        label: t('calendar.details'),
                                        value: <CalendarServiceDetails
                                            inputs={details}
                                            handleOnDetailsInputChanged={handleOnDetailsInputChanged}
                                            handleOnDeleteCustomSelect={handleOnDeleteCustomSelect}
                                            handleEnterPressed={handleEnterPressed}
                                        />
                                    },
                                    {
                                        label: t('calendar.date'),
                                        value: <CalendarServiceDate
                                            isClubAdministrator={isClubAdministrator}
                                            isBusinessAdministrator={isBusinessAdministrator}
                                            inputs={dates}
                                            trainers={trainers}
                                            whenType={whenType}
                                            setWhenType={newWhenType => {
                                                setWhenType(newWhenType)
                                                setSchedule([
                                                    { name: 'mon', active: false, values: [] },
                                                    { name: 'tue', active: false, values: [] },
                                                    { name: 'wed', active: false, values: [] },
                                                    { name: 'thu', active: false, values: [] },
                                                    { name: 'fri', active: false, values: [] },
                                                    { name: 'sat', active: false, values: [] },
                                                    { name: 'sun', active: false, values: [] }
                                                ])
                                            }}
                                            selectedTrainerIds={selectedTrainerIds}
                                            handleSelectTrainer={handleSelectTrainer}
                                            handleRemoveTrainer={handleRemoveTrainer}
                                            handleOnNotificationChanged={handleOnNotificationChanged}
                                            handleOnDatesInputChanged={handleOnDatesInputChanged}
                                            schedule={schedule}
                                            handleChangeValueFromSchedule={handleChangeValueFromSchedule}
                                            handleRemoveValueFromSchedule={handleRemoveValueFromSchedule}
                                            handleAddValueToSchedule={handleAddValueToSchedule}
                                            handleFocusInput={(value) => {
                                                setDateInputFocused(value)
                                            }}
                                            dateInputFocused={dateInputFocused}
                                        />
                                    },
                                    {
                                        label: t('calendar.notes'),
                                        value: (
                                            <CalendarServiceNotes notes={notes} handleOnNotesChanged={handleOnNotesChanged} />
                                        )
                                    }
                                ]}
                            />
                        </Grid>
                        <Grid
                            item
                            display="flex"
                            flexDirection={{ lg: 'row', xs: 'column' }}
                            alignItems="center"
                            justifyContent="center"
                            pb={4}
                        >
                            <Grid item pr={{ lg: 2, xs: 0 }} pb={{ lg: 0, xs: 1 }} minWidth={250} width={{ lg: 'auto', xs: '100%' }}>
                                <Button onClick={handleOnPrevButton} fullWidth variant="outlined">
                                    {activeTab === 0 ? t('button.cancel') : t('button.prev')}
                                </Button>
                            </Grid>
                            <Grid item pl={{ lg: 2, xs: 0 }} pt={{ lg: 0, xs: 1 }} minWidth={250} width={{ lg: 'auto', xs: '100%' }}>
                                <Button disabled={disable} onClick={handleOnNextButton} fullWidth variant="contained">
                                    {activeTab === 2 ? t('button.save') : t('button.next')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>

            </Container>
        </Dialog>
    )
}

export default CalendarCreateService
