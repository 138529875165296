import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Typography, Button, Checkbox, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { useParams, useSearchParams } from 'react-router-dom'
import ReactGA from 'react-ga'
import jwt_decode from 'jwt-decode'

import BG_IMAGE from '../../../assets/login.png'
import { InputGenerator, SendingButton as SendingButtonChild } from '../../common'
import { BLACK, GENDER_FEMALE, GENDER_MALE, GENDER_OTHER, PRIMARY_COLOR } from '../../../utils/constants'
import { computeArrayOfInputsWithError } from '../../../utils/helpers/validator'
import { useAuthentication } from '../../../context/auth-context'

import * as AUTH_REQUESTS from '../../../api/auth'
import * as COMPANY_REQUESTS from '../../../api/company'
import { BootstrapButton } from '../styles'

const TypeAthlete = ({ handlePrev, handleSubmit, sending, error }) => {
  const { t, i18n } = useTranslation()
  const { domain } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { handleLoggedIn, handleToken, handleUser } = useAuthentication()

  const LOGIN_INPUTS = [
    {
      name: 'email',
      placeholder: t('input.placeholder.email'),
      label: t('input.label.email'),
      value: '',
      type: 'text',
      mandatory: true
    },
    {
      name: 'password',
      placeholder: t('input.placeholder.password'),
      label: t('input.label.password'),
      value: '',
      type: 'password',
      mandatory: true
    },
  ]

  const INPUTS = [
    {
      name: 'firstName',
      placeholder: t('input.placeholder.firstName'),
      label: t('input.label.firstName'),
      value: '',
      type: 'text',
      level: 1,
      mandatory: true
    },
    {
      name: 'lastName',
      placeholder: t('input.placeholder.lastName'),
      label: t('input.label.lastName'),
      value: '',
      type: 'text',
      level: 1,
      mandatory: true
    },
    {
      name: 'gender',
      label: t('input.label.gender'),
      value: GENDER_MALE,
      values: [
        { value: GENDER_MALE, label: 'Male' },
        { value: GENDER_FEMALE, label: 'Female' },
        { value: GENDER_OTHER, label: 'Other' }
      ],
      type: 'radio',
      level: 2,
      mandatory: true
    },
    {
      name: 'email',
      placeholder: t('input.placeholder.email'),
      label: t('input.label.email'),
      value: '',
      type: 'text',
      level: 3,
      mandatory: true
    },
    {
      name: 'phoneNumber',
      placeholder: t('input.placeholder.phoneNumber'),
      label: t('input.label.phoneNumber'),
      value: '',
      type: 'phone',
      level: 3,
      mandatory: true
    },
    {
      name: 'password',
      placeholder: t('input.placeholder.password'),
      label: t('input.label.password'),
      value: '',
      type: 'password',
      level: 4,
      mandatory: true
    },
    {
      name: 'confirmPassword',
      placeholder: t('input.placeholder.confirmPassword'),
      label: t('input.label.confirmPassword'),
      value: '',
      type: 'password',
      level: 5,
      mandatory: true
    },
    {
      name: 'address',
      placeholder: t('input.placeholder.address'),
      label: t('input.label.address'),
      value: '',
      type: 'google',
      level: 6,
      mandatory: true
    },
    {
      name: 'city',
      placeholder: t('input.placeholder.city'),
      label: t('input.label.city'),
      value: '',
      type: 'text',
      level: 7,
      mandatory: true
    },
    {
      name: 'country',
      placeholder: t('input.placeholder.country'),
      label: t('input.label.country'),
      value: '',
      type: 'text',
      level: 7,
      mandatory: true
    },
  ]

  const [loginInputs, setLoginInputs] = useState([])
  const [inputs, setInputs] = useState([])
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [wrongInputs, setWrongInputs] = useState([])
  const [locationLat, setLocationLat] = useState()
  const [locationLng, setLocationLng] = useState()
  const [locationName, setLocationName] = useState('')
  const [hasAccount, setHasAccount] = useState(false)
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState()

  useEffect(() => {
    if (searchParams.get('serviceId')) {
      setTermsAccepted(true)
    }
    setInputs([...INPUTS])
  }, [searchParams])

  useEffect(() => {
    if (error === 'EMAIL_EXISTS') {
      setWrongInputs((prevValue) => {
        const prevValueWithoutEmail = [...prevValue].filter((el) => el.name !== 'email')
        return [...prevValueWithoutEmail, { name: 'email', messageKey: 'EMAIL_EXISTS' }]
      })
    } else if (error === 'PHONE_NUMBER_EXISTS') {
      setWrongInputs((prevValue) => {
        const prevValueWithoutEmail = [...prevValue].filter((el) => el.name !== 'phoneNumber')
        return [
          ...prevValueWithoutEmail,
          { name: 'phoneNumber', messageKey: 'PHONE_NUMBER_EXISTS' }
        ]
      })
    }
  }, [error])

  useEffect(() => {
    if (hasAccount && loginInputs.length === 2 && loginInputs[0].value && loginInputs[1].value) {
      setTermsAccepted(true)
    }
  }, [hasAccount, loginInputs])

  const handleGetCompany = useCallback(async () => {
    if (!domain) return
    try {
      const company = await COMPANY_REQUESTS.getBusinessByDomain(domain)
      setCompany(company)
    } catch (e) {
      console.error(e)
    }
  }, [domain])


  useEffect(() => {
    if (!domain) return
    setLoginInputs([...LOGIN_INPUTS])
    handleGetCompany()
  }, [domain])

  const handleOnChange = (event) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el.name !== event.target.name)])
    setInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === event.target.name)
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value = event.target.value

      return prevValuesCopy
    })
  }

  const handleOnChangeLoginInputs = (event) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el.name !== event.target.name)])
    setLoginInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === event.target.name)
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value = event.target.value

      return prevValuesCopy
    })
  }


  const handleOnChangeGoogle = (googleData) => {
    setLocationName(googleData.label)
    geocodeByAddress(googleData.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLocationLat(lat)
        setLocationLng(lng)
      })
  }

  const handleOnChangePhone = (phone) => {
    setWrongInputs((prevValues) => [...prevValues.filter((el) => el.name !== 'phoneNumber')])
    setInputs((prevValues) => {
      const inputPos = prevValues.findIndex((f) => f.name === 'phoneNumber')
      if (inputPos < 0) return [...prevValues]

      const prevValuesCopy = [...prevValues]
      prevValuesCopy[inputPos].value = phone

      return prevValuesCopy
    })
  }

  const handleLogin = async () => {
    try {
      const inputsWithError = computeArrayOfInputsWithError(loginInputs)
      let wrong = false
      if (inputsWithError && inputsWithError.length > 0) {
        setWrongInputs([...inputsWithError])
        wrong = true
      }

      const data = {}

      loginInputs.forEach(el => {
        data[el.name] = el.value
      })

      if (wrong) return

      const { token, refreshToken, user } = await AUTH_REQUESTS.login(data.email, data.password, Number(domain))

      const tokenDecoded = jwt_decode(token)
      const userLanguage = tokenDecoded.language ? tokenDecoded.language === 'ENG' ? 'en' : tokenDecoded.language.toLowerCase() : tokenDecoded.user.language ? tokenDecoded.user.language === 'EN' ? 'en' : tokenDecoded.user.language.toLowerCase() : 'en'
      i18n.changeLanguage(userLanguage)
      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('email', user.email)
      ReactGA.event({
        category: 'PRE_LOGIN',
        action: 'LOGIN',
        label: 'SUCCESS'
      })

      handleToken(token)
      handleUser(user)
      handleLoggedIn(true)
      navigate('/')

    } catch (e) {
      setLoading(false)

      ReactGA.event({
        category: 'PRE_LOGIN',
        action: 'LOGIN',
        label: 'ERROR'
      })
    }
  }

  const handleOnSubmit = () => {
    let wrong = false
    const inputsWithError = computeArrayOfInputsWithError(inputs)

    if (inputsWithError && inputsWithError.length > 0) {
      setWrongInputs([...inputsWithError])
      wrong = true
    }

    const registerData = {}
    inputs.forEach((input) => {
      if (input.type !== 'google') {
        registerData[input.name] = input.value
      }
    })

    if (registerData.password !== registerData.confirmPassword) {
      setWrongInputs([
        ...inputsWithError,
        { name: 'confirmPassword', messageKey: 'password_match' }
      ])
      wrong = true
    }

    if (wrong) return

    if (locationLat) {
      registerData['locationLat'] = String(locationLat)
    }
    if (locationLng) {
      registerData['locationLng'] = String(locationLng)
    }
    registerData['locationName'] = locationName
    registerData['acceptedTerms'] = true

    handleSubmit({ ...registerData, ...(domain ? { associatedGymId: Number(company.id) } : {}) })
  }

  const handleOnChangeTerms = (event) => setTermsAccepted(event.target.checked)

  const filterInputs = useCallback(
    (level) => inputs.filter((input) => input.level === level),
    [inputs]
  )

  const computeHelperText = (input) => {
    if (wrongInputs.map((el) => el.name).includes(input.name)) {
      if (input.value === '' && input.name !== 'confirmPassword')
        return `${input.label} ${t('signup.cantBeEmpty')}`
      if (input.name === 'email' || input.name === 'phoneNumber') {
        const inputIndex = wrongInputs.findIndex((el) => el.name === input.name)
        if (inputIndex >= 0) {
          return t(`input.error.${wrongInputs[inputIndex].messageKey}`)
        }
      }
      return t(`input.error.${input.name}`)
    }
  }

  const renderForm = useMemo(() => {
    const levels = inputs.map((el) => el.level)
    const levelsWithoutDuplicates = levels.filter((element, index) => {
      return levels.indexOf(element) === index
    })
    const conditionForDomainInUrl = domain && hasAccount === false || !domain

    return (
      <Grid item xs={12} lg={6} p={{ xs: 4, lg: 10 }} pr={{ xs: 2 }}>
        <Grid container alignItems="center">
          <Grid container direction="column" rowSpacing={domain ? hasAccount === true ? 0 : 4 : 4}>
            {conditionForDomainInUrl ? <Grid item>
              <Typography fontWeight="bold" variant="h4" style={{ color: BLACK }}>
                {t('title.aboutYou')}
              </Typography>
            </Grid> : null}
            {conditionForDomainInUrl ? <Grid item>
              <Grid container flexDirection="column">
                {levelsWithoutDuplicates.map((level, index) => {
                  const inputsToUse = filterInputs(level)
                  return (
                    <Grid pb={3} key={index} container flexDirection="row">
                      {inputsToUse.map((inp, idx) => {
                        return (
                          <Grid
                            pr={{ lg: idx !== inputsToUse.length - 1 ? 4 : 0, xs: 0 }}
                            lg={12 / inputsToUse.length}
                            xs={12}
                            key={idx}
                            item
                          >
                            <InputGenerator
                              input={{ ...inp, error: computeHelperText(inp) }}
                              handleOnChangePhone={handleOnChangePhone}
                              handleOnChangeGoogle={handleOnChangeGoogle}
                              handleOnChange={handleOnChange}
                              error={wrongInputs.map((el) => el.name).includes(inp.name)}
                              helperText={computeHelperText(inp)}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  )
                })}
              </Grid>
              <Grid
                maxWidth={{ lg: '75%', xs: '90%' }}
                container
                flexDirection="row"
                alignItems="center"
              >
                <Grid item>
                  <Checkbox sx={{
                    color: BLACK,
                    '&.Mui-checked': {
                      color: BLACK,
                    },
                  }} onChange={handleOnChangeTerms} checked={termsAccepted} />
                </Grid>
                <Grid xs={10} item>
                  <Typography variant="p" fontSize={14}>
                    {t('helper.agreeWith')}
                    <Typography
                      variant="p"
                      color={PRIMARY_COLOR}
                      fontWeight={600}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => window.open('https://sportin.io/privacy-policy/')}
                    >
                      &nbsp;{t('helper.privacyPolicy')}&nbsp;
                    </Typography>{' '}
                    {t('helper.asWell')}
                    <Typography
                      variant="p"
                      color={PRIMARY_COLOR}
                      fontWeight={600}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() => window.open('https://sportin.io/privacy-policy/')}
                    >
                      &nbsp;{t('helper.termsAndConditions')}&nbsp;
                    </Typography>{' '}
                    {t('helper.ofSportIn')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> : null}
            <Grid item display="flex" flexDirection="row">
              <Grid container display="flex" flexDirection="row">
                <Grid item paddingRight={{ lg: 4, xs: 1 }} xs={6} lg={4}>
                  <BootstrapButton fullWidth onClick={handlePrev} style={{ color: BLACK }} variant="outlined">
                    {t('button.back')}
                  </BootstrapButton>
                </Grid>
                <Grid item paddingLeft={{ lg: 4, xs: 1 }} xs={6} lg={8}>
                  <Button
                    disabled={sending || !termsAccepted || loading}
                    fullWidth
                    onClick={() => hasAccount && domain ? handleLogin() : handleOnSubmit()}
                    style={{ backgroundColor: BLACK, color: '#fff' }}
                    variant="contained"
                  >
                    {!sending || loading ? t('button.getStarted') : <SendingButtonChild />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [inputs, locationLat, locationLng, searchParams, handleOnSubmit, sending, termsAccepted, wrongInputs])

  const renderImage = useMemo(() => {
    return (
      <Grid
        item
        alignItems="center"
        display={{ xs: 'none', sm: 'none', lg: 'block' }}
        lg={6}
        maxHeight={'98vh'}
      >
        <img
          style={{ position: 'relative', left: '3%', top: domain && hasAccount === true ? '-55%' : '-30%', objectFit: 'cover' }}
          height={'150%'}
          src={BG_IMAGE}
          width={1200}
        />
      </Grid>
    )
  }, [hasAccount, domain])

  const renderWithAccountOrNot = useMemo(() => {
    if (!domain || !company) return null

    return (
      <Grid item xs={12}>
        <Grid container display="flex" flexDirection="column" width="100%" pl={{ xs: 4, lg: 10 }} pt={{ lg: 2, xs: 2 }} pr={{ xs: 2 }}>
          <Grid item xs={12} lg={12}>
            <Typography fontWeight="bold" variant="h4" color="primary">
              {hasAccount ? `${t('signup.link')} ${company.name || '-'}` : `${t('signup.register')} ${company.name || '-'}`}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} pb={1.5}>
            <Typography fontWeight={500} variant="subtitle1">
              {t('title.haveAccount')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel checked={hasAccount === true} onChange={() => setHasAccount(true)} control={<Radio />} label={t('signup.yesIDo')} />
              <FormControlLabel checked={hasAccount === false} onChange={() => setHasAccount(false)} control={<Radio />} label={t('signup.noIDont')} />
            </RadioGroup>
          </Grid>
          {hasAccount === true ? <Grid item xs={12} pb={2} pt={5}>
            {loginInputs.map((inp, idx) => (
              <Grid
                pt={2}
                lg={5.5}
                pr={{ lg: 4.5, xs: 0 }}
                xs={12}
                key={idx}
                item
              >
                <InputGenerator
                  input={{ ...inp, error: computeHelperText(inp) }}
                  handleOnChange={handleOnChangeLoginInputs}
                  error={wrongInputs.map((el) => el.name).includes(inp.name)}
                  helperText={computeHelperText(inp)}
                />
              </Grid>
            ))}
          </Grid> : null}
        </Grid>
      </Grid>
    )
  }, [domain, hasAccount, loginInputs, wrongInputs, company])

  return (
    <Grid container style={{ padding: 0, overflowX: 'hidden' }} height="100%">
      {renderWithAccountOrNot}
      {renderForm}
      {renderImage}
    </Grid>
  )
}

export default TypeAthlete
