import React, { useEffect, useState, useMemo } from 'react'
import { Grid, Container, CircularProgress, Box } from '@mui/material'
import moment from 'moment'

import UserActivity from './subcomponents/UserActivity'
import UserDetails from './subcomponents/UserDetails'
import { Footer } from '../common'

import * as AUTH_REQUESTS from '../../api/auth'

const UserProfile = () => {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  const getUser = () => {
    setLoading(true)
    AUTH_REQUESTS.getMe()
      .then((user) => {
        setUser({ ...user, createdAt: moment(new Date(user.createdAt)).format('MMMM, YY') })
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getUser()
  }, [])

  const renderUserDetails = useMemo(() => {
    return <UserDetails user={user} handleGetMe={getUser} onEditCloseHandler={getUser} />
  }, [user])

  const renderUserActivity = useMemo(() => {
    return <UserActivity handleGetMe={getUser} user={user} />
  }, [user])

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!user) return null

  return (
    <Container maxWidth="xl">
      <Grid container pt={5} pb={5} display="flex" flexDirection="row" width="100%">
        <Grid item lg={3} xs={12} md={12} sm={12} p={2} pr={{ xs: 0, lg: 2 }} pl={{ xs: 0 }}>
          {renderUserDetails}
        </Grid>
        <Grid item lg={9} xs={12} md={12} sm={12} p={2} pr={{ xs: 0 }} pl={{ xs: 0 }}>
          {renderUserActivity}
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserProfile
